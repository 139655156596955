<template>
	<div class="exchange">
		<!-- <common-head :title="$t('system.aboutus')" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="$t('xinzeng.huanbi')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="g_safe_top">
			<div class="layoutBox">
				<div class="old_zichan">
					<div class="z_left">
						<div class="zi_name">{{ obj_1 }}</div>
						<div class="yu">{{$t('system.AvailableBalance')}}：{{ ass_1 }} {{ obj_1 }}</div>
					</div>
					<div class="z_right">
						<input type="text" class="inp" v-model="amount" @input="setArrival" :placeholder="$t('system.qingshuruduihuanshuliang')" />
					</div>
				</div>
				<div class="zhuanhuan" @click="jiaohuan()">
					<img src="../../assets/imgs/new_color/zhuanhuan.png" >
				</div>
				<div class="new_zichan">
					<div class="z_left">
						<div class="zi_name">{{ obj_2 }}</div>
						<div class="yu">{{$t('system.AvailableBalance')}}：{{ ass_2 }} {{ obj_2 }}</div>
					</div>
					<div class="z_right">
						{{assest}}
					</div>
				</div>
				<div class="bottom">
					<div class="button" @click="duihuan()">{{$t('system.ConfirmSwap')}}</div>
				</div>
			</div>
			<van-dialog v-model="show" :title="$t('system.tip')" show-cancel-button :message="$t('system.ConfirmSwapDialog')" @confirm="yes"></van-dialog>
		</div>
		
	</div>
</template>

<script>
export default {
	name: 'exchange',
	data() {
		return {
			amount: '',
			assest: 0,
			show: false,
			assetsDitale: null,
			fow: null,
			danwei2: null,
			danwei1:null,
			ass_2: null,
			ass_1: null,
			obj_1: null,
			obj_2: null,
			FOW_to_USDT:'',
			USDT_to_FOW: ''
		};
	},
	created() {
		this.getAssets();
	},
	mounted() {
		this.setArrival()
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		// 获取资产信息
		getAssets() {
			this.$post2('User/Api/Index/userAsset').then(res => {
				console.log(res);
				// 总资产是 amout + frozen
				this.FOW_to_USDT = res.changeRate['FOW:USDT'];
				this.USDT_to_FOW = res.changeRate['USDT:FOW'];
				//USDT
				let t1 = res.USDT.amount + res.USDT.frozen;
				this.assetsDitale = t1.toFixed(2);
				let o1 = Object.keys(res)[0];
				this.danwei1 = o1;
				this.ass_2 = this.assetsDitale;
				this.obj_2 = this.danwei1;
				//FOW
				let t2 = res.FOW.amount + res.FOW.frozen;
				this.fow = t2.toFixed(2);
				let o2 = Object.keys(res)[2];
				this.danwei2 = o2;
				this.ass_1 = this.fow;
				this.obj_1 = this.danwei2;
			});
		},
		setArrival() {
			if (Number(this.amount) > this.ass_1) {
			    // this.$toast('超出可用余额')
			    this.$toast(this.$t('system.beyondBalance'))
			    this.amount = this.ass_1
			}
			if(this.obj_1 == 'FOW'){
				this.assest = this.amount * this.FOW_to_USDT
			}else {
				this.assest = this.amount * this.USDT_to_FOW
			}
		},
		jiaohuan() {
			this.assest = null;
			this.amount = null;
			if(this.obj_1 == this.danwei2) {
				this.ass_1 = this.assetsDitale;
				this.obj_1 = this.danwei1;
				this.ass_2 = this.fow;
				this.obj_2 = this.danwei2;
				
			}else {
				this.ass_1 = this.fow;
				this.obj_1 = this.danwei2;
				this.ass_2 = this.assetsDitale;
				this.obj_2 = this.danwei1;
			}
		},
		duihuan() {
			this.show = true
		},
		yes() {
			this.changeUserAsset()
		},
		//资产兑换
		changeUserAsset() {
			this.$post2('User/Api/Index/changeUserAsset', {
				token: this.obj_1,
				totoken: this.obj_2,
				amount: this.amount
			})
			.then(res => {
				// this.$toast.success('兑换成功');
				this.$toast.success(this.$t('system.swapSuc'));
				this.getAssets();
			})
			.catch(e => {
				this.$toast.fail(e);
			});
		}
	}
};
</script>

<style lang="less" scoped>
.layoutBox {
	margin: 0 0.2rem;
	margin-top: 0.2rem;
	font-family: PingFangSC-Regular;
	.old_zichan {
		border-radius: 0.16rem;
		background-color: #FFFFFF;
		padding: 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.z_left {
			.zi_name {
				font-family: PingFangSC-Medium;
				color: #333333;
				font-size: 0.28rem;
				font-weight: 550;
			}
			.yu {
				margin-left: -0.23rem;
				color: #6F7A8B;
				font-size: 0.24rem;
				transform: scale(0.83);
			}
		}
		.z_right {
			.inp {
				font-family: PingFangSC-Medium;
				width: 1.68rem;
				height: 0.33rem;
				line-height: 0.33rem;
				font-size: 0.28rem;
				color: #333333;
				border: none;
				font-weight: 550;
				text-align: right;
			}
			input::-webkit-input-placeholder {
			    /* 修改字体颜色 */
			    color: #6F7A8B;
				font-weight: 500;
				font-size: 0.24rem;
			}
		}
	}
	.zhuanhuan {
		margin: 0 auto;
		// padding: 0.18rem;
		background-color: #F7F7FA;
		width: 0.6rem;
		height: 0.6rem;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: -0.2rem;
		position: relative;
		z-index: 10;
		img {
			width: 0.42rem;
			height: 0.42rem;
		}
	}
	.new_zichan {
		margin-top: -0.2rem;
		border-radius: 0.16rem;
		background-color: #FFFFFF;
		padding: 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.z_left {
			.zi_name {
				font-family: PingFangSC-Medium;
				color: #333333;
				font-size: 0.28rem;
				font-weight: 550;
			}
			.yu {
				margin-left: -0.23rem;
				color: #6F7A8B;
				font-size: 0.24rem;
				transform: scale(0.83);
			}
		}
		.z_right {
			font-family: PingFangSC-Medium;
			font-size: 0.28rem;
			color: #333333;
			font-weight: 550;
			text-align: right;
		}
	}
	.bottom {
		position: fixed;
		left: 0;
		bottom: 0;
		padding: 0;
		.button {
			margin: 0.14rem 0.3rem 0.14rem 0.28rem;
			width: 6.9rem;
			height: 0.88rem;
			line-height: 0.88rem;
			background-color: #fad538;
			color: #333333;
			font-size: 0.32rem;
			text-align: center;
			border-radius: 0.16rem;
			font-weight: 500;
		}
	}
}
</style>
